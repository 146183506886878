import { useEffect, useState } from "react";
import { Header } from "../Header";
import { PromptForm } from "../PromptForm";
import { ResultsOutput } from "../ResultsOutput";
import { useSearchParams } from "react-router-dom";
import { GET_SHARED_RESULT_URL } from "../../constants/resultsLogUrls";
import { EVOLUSION_MODEL_URL, OPENAI_URL } from "../../constants/AiModelUrls";
import styles from "./styles.module.scss";

const REQ_HEADERS = {
	'Accept': 'application/json',
	'Content-Type': 'application/json'
};

export function DemoPageLayout() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [promptInput, setPromptInput] = useState("");
	const [evolusionLlmResult, setEvolusionLlmResult] = useState("");
	const [openAiLlmResult, setOpenAiLlmResult] = useState("");
	const [shareId, setShareId] = useState("");
	const [evolusionLoading, setEvolusionLoading] = useState(true);
	const [openAiLoading, setOpenAiLoading] = useState(true);
	const [showSuggestions, setShowSuggestions] = useState();

	// Load shared outputs if present
	useEffect(() => {
		const shareId = searchParams.get("shareId");
		if (shareId) {
			fetch(GET_SHARED_RESULT_URL, {
				method: 'POST',
				headers: REQ_HEADERS, 
				body: JSON.stringify({ shareId })
			})
				.then((response) => response.json())
				.then(({ shareId, prompt, openAiCompletion, evolusionCompletion }) => {
					setPromptInput(prompt.trim());
					setEvolusionLlmResult(evolusionCompletion);
					setEvolusionLoading(false);
					setOpenAiLlmResult(openAiCompletion);
					setOpenAiLoading(false);
					setShareId(shareId);
				})
				.catch((e) => {
					console.log("Error", e)
					setEvolusionLoading(false);
					setOpenAiLoading(false);
				});
		} else {
			setEvolusionLoading(false);
			setOpenAiLoading(false);
		}
	}, [searchParams]);


	// Wake up the lambdas by pinging them
	useEffect(() => {
		const REQ_HEADERS = {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		};
		[EVOLUSION_MODEL_URL, OPENAI_URL].forEach(
			(url) => fetch(url, {
				method: 'POST',
				headers: REQ_HEADERS,
				body: JSON.stringify({ prompt: "PING" }),
			})
		);
	}, []);

	return (
		<div className={styles.container}>
			<Header />
			<PromptForm
				promptInput={promptInput}
				setSearchParams={setSearchParams}
				setShareId={setShareId}
				setEvolusionLlmResult={setEvolusionLlmResult}
				setOpenAiLlmResult={setOpenAiLlmResult}
				loading={evolusionLoading || openAiLoading}
				setEvolusionLoading={setEvolusionLoading}
				setOpenAiLoading={setOpenAiLoading}
				showSuggestions={showSuggestions}
				setShowSuggestions={setShowSuggestions}
			>
				<ResultsOutput
					evolusionLlmResult={evolusionLlmResult}
					openAiLlmResult={openAiLlmResult}
					shareId={shareId}
					evolusionLoading={evolusionLoading}
					openAiLoading={openAiLoading}
					suggestionsShown={showSuggestions}
				/>
			</PromptForm>
		</div>
	);
}
