import { useEffect, useState } from "react";
import { Button } from "../Button";
import { BulbIcon } from "../../icons/Bulb";
import { Suggestions } from "./components/Suggestions";
import { RECORD_RESULT_URL } from "../../constants/resultsLogUrls";
import { EVOLUSION_MODEL_URL, OPENAI_URL } from "../../constants/AiModelUrls";
import styles from "./styles.module.scss";

const REQ_HEADERS = {
	'Accept': 'application/json',
	'Content-Type': 'application/json'
};
const KEY_CODE_ENTER = 13;

const ERR_MSG = "Something went wrong with the request, please try again.";

function queryModel(prompt, modelUrl, setLoading, setResult) {
	setLoading(true);
	return fetch(modelUrl, {
		method: 'POST',
		headers: REQ_HEADERS, 
		body: JSON.stringify({ prompt })
	})
		.then((response) => response.json())
		.then(({ completion }) => {
			setResult(completion);
			setLoading(false);
			return completion;
		})
		.catch(() => {
			setResult(ERR_MSG);
			setLoading(false);
			return ERR_MSG;
		});
}

function growAsNeeded(e) {
	e.target.style.height = 'inherit';
	e.target.style.height = `${Math.min(e.target.scrollHeight, 300)}px`;
}

export function PromptForm({
	children,
	promptInput,
	setEvolusionLlmResult,
	setOpenAiLlmResult,
	setSearchParams,
	setShareId,
	loading,
	setEvolusionLoading,
	setOpenAiLoading,
	showSuggestions,
	setShowSuggestions,
}) {
	const [prompt, setPrompt] = useState();

	useEffect(() => {
		if (promptInput) {
			setPrompt(promptInput)
		}
	}, [promptInput]);
	const [clientInfo, setClientInfo] = useState("");

	useEffect(() => {
		if (!clientInfo) {
			try {
				fetch('https://geolocation-db.com/json/')
					.then((response) => response.json())
					.then((response) => setClientInfo(response || ""));
			} catch (e) {
				console.log(e);
			}
		}
	}, []);

	function handleSubmit(promptToSubmit) {
		const queries = [
			queryModel(
				promptToSubmit,
				EVOLUSION_MODEL_URL,
				setEvolusionLoading,
				setEvolusionLlmResult
			),
			queryModel(
				promptToSubmit,
				OPENAI_URL,
				setOpenAiLoading,
				setOpenAiLlmResult
			),
		];
		Promise.all(queries)
			.then(([evolusionCompletion, openAICompletion]) => {
				fetch(RECORD_RESULT_URL, {
					method: 'POST',
					headers: REQ_HEADERS, 
					body: JSON.stringify({
						prompt: prompt.trim(),
						evolusionCompletion,
						openAICompletion,
						clientInfo,
					})
				})
				.then((response) => response.json())
				.then(({ shareId }) => {
					setSearchParams({ shareId });
					setShareId(shareId);
				});
			});
	}

	function submitSuggestedPrompt(suggestedPrompt) {
		setPrompt(suggestedPrompt);
		handleSubmit(suggestedPrompt);
	}

	function clearForm() {
		setPrompt("");
		setEvolusionLlmResult("");
		setOpenAiLlmResult("");
	}

	function onPromptChange(e) {
		setPrompt(e.target.value);
		growAsNeeded(e);
	}

	function onPromptKeyUp(e) {
		// Submit on Enter. Allow \n on Shift+Enter
		if (e.keyCode === KEY_CODE_ENTER && !e.shiftKey) {
			e.preventDefault();
			const trimmedPrompt = prompt.trim();
			setPrompt(trimmedPrompt);
			handleSubmit(trimmedPrompt);
		}
	}

	return (
		<div className={styles.container}>
			<div className={styles.inputRow}>
				<div className={styles.labelRow}>
					<div className={styles.inputLabel}>
						Prompt
					</div>
					<div
						className={styles.suggestCta}
						onClick={() => setShowSuggestions(!showSuggestions)}
					>
						<div><BulbIcon size={16} /></div>
						<div>{showSuggestions ? "Hide" : "Show"} suggestions</div>
					</div>
				</div>
				<Suggestions show={showSuggestions} submitPrompt={submitSuggestedPrompt} />
				<textarea
					className={styles.textArea}
					placeholder="Type a prompt for the AI models and then press Enter or hit Compare to see the results."
					onChange={onPromptChange}
					onKeyUp={onPromptKeyUp}
					value={prompt}
				/>
			</div>
			<div className={styles.childrenRow}>
				{children}
			</div>
			<div className={styles.controlRow}>
				<div>
					<Button
						emphasis="secondary"
						disabled={loading || !Boolean(prompt)}
						onClick={clearForm}
					>
						Clear
					</Button>
				</div>
				<div>
					<Button
						emphasis="primary"
						disabled={loading || !Boolean(prompt)}
						onClick={() => handleSubmit(prompt)}
					>
						Compare
					</Button>
				</div>
			</div>
		</div>
	);
}
