export const SUGGESTIONS_LIST = [
	"How do I swap the values of two variables without using a temporary variable in Python?",
	"How can I reverse a string in Python?",
	"How do I swap the values of two variables without using a temporary variable in Java?",
	"How do I iterate over the words of a string composed of words separated by whitespace using C++?",
	"How do I remove a property from a JavaScript object?",
	"Explain to me the Finite Elemente Method",
    "What's the root cause of ongoing culture war in the United States?",
    "Who is the best example of \"You either die a hero or live long enough to see yourself become a villain\"?",
    "What is your number 1 obscure animal fact?",
    "What are some VERY comforting facts?",
    "Why do gravitational waves travel at the speed of light?",
    "List some bizzare historical events you can't believe actually took place?",
    "Which are the current important topics for research in physics?",
    "List some quotes that permanently changed the way you look at things.",
    "Create a personalized email greeting for a VIP customer",
    "Write a poem in the style of Robert Frost for the college introductory physics class.",
    "Describe Redux in a song using biblical language.",
	"How can I horizontally center an element in html?",
	"How do I return the response from an Observable/http/async call in angular?",
	"How can I prevent SQL injection in PHP?",
	"Difference between sh and Bash",
	"What are the use(s) for struct tags in Go?",
	"What is a NullReferenceException, and how do I fix it?",
	"How to style a checkbox using CSS",
	"Unable to resolve dependency tree error when installing npm packages",
	"Convert bytes to a string in python",
	"Write a very little story about Markus and Katharina, two people who are infamous for being late.",
    "Can you provide me with some ideas for blog posts about unsubscribing from emails?",
    "How can I obtain high-quality backlinks to raise the SEO of my website?",
    "Plan a week-long itinerary for exploring the beautiful landscapes of New Zealand, including both the North and South Islands.",
    "Write me a five line poem depicting how a thorn on a rose bush sees itself protecting the rose",
    "Write a poem about social life on the internet.",
    "Can you suggest some classic movies from the 1950s that every film enthusiast should watch?",
    "Make a song about a programmer and someone who isn’t a programmer.",
    "Please make snowflakes with JavaScript.",
    "Describe a terrible hangover using the language of a Renaissance English aristocrat.",
    "Write a short, comical story about an alien who came to Earth just to try out fast food.",
    "Imagine a parallel universe where gravity is optional. Describe a funny day in the life of a person living there.",
    "If coffee and tea could argue, what would they fight about?",
    "Justify why a penguin might make a good king of the jungle.",
];
