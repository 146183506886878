import { SUGGESTIONS_LIST } from "./constants";
import cn from "classnames";
import styles from "./styles.module.scss";

function renderSuggestion(submitPrompt) {
	return function render(prompt) {
		return (
			<div
				className={styles.prompt}
				onClick={() => submitPrompt(prompt)}
				key={prompt}
			>
				{prompt}
			</div>
		);
	}
}

export function Suggestions({ show = false, submitPrompt }) {
	return (
		<div className={cn(styles.container, { [styles.shown]: show })}>
			<div className={styles.suggestionsList}>
				{SUGGESTIONS_LIST.map(renderSuggestion(submitPrompt))}
			</div>
		</div>
	);
}
