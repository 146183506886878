import cn from "classnames";
import { useEffect, useState, useRef } from "react";
import { Button } from "../Button";
import { randSplit } from "../../utils/randSplit";
import { getCumulativesFromList } from "../../utils/getCumulativesFromList";
import { useResizeWatcher } from "../../utils/useResizeWatcher";
import { FormattedLLMOutput } from "../FormattedLLMOutput";
import styles from "./styles.module.scss";

const RESULT_DEFAULT_HEIGHT_OFFSET = 332;
const RESULT_WITH_SUGGESTIONS_HEIGHT_OFFSET = 552;
const ANIMATION_DELAY = 70;

let timer;

function chunkAndAnimateStr(str, setStr, multiplier) {
	const strArr = str.split("");
	const substrArrsArr = randSplit(strArr, 1, 9);
	const substrsArr = substrArrsArr.map((substrArr) => substrArr.join(""));
	const substrsCumulative = getCumulativesFromList(substrsArr, "");

	substrsCumulative.forEach((substr, i) => setTimeout(function() {
		setStr(substr)
	}, ANIMATION_DELAY * i * multiplier));
}

export function ResultsOutput({
	evolusionLlmResult,
	openAiLlmResult,
	shareId,
	evolusionLoading,
	openAiLoading,
	suggestionsShown,
}) {
	const [evolusionAnimated, setEvolusionAnimated] = useState("");
	const [openAiAnimated, setOpenAiAnimated] = useState("");
	const [resultHeight, setResultHeight] = useState(0);
	const [shareBtnText, setShareBtnText] = useState("Share");
	const containerRef = useRef();
	const size = useResizeWatcher(containerRef);

	function copyShareLink() {
		if (shareId) {
			const shareLink = `https://evolusion.ai/demo?shareId=${shareId}`;
			navigator.clipboard.writeText(shareLink);
			setShareBtnText("Link copied!");
		} else {
			setShareBtnText("Error!");
		}
		window.setTimeout(() => setShareBtnText("Share"), 3000);
	}

	// Perform text animation
	useEffect(() => {
		if (evolusionLlmResult) {
			chunkAndAnimateStr(evolusionLlmResult, setEvolusionAnimated, 1);
		}
	}, [evolusionLlmResult]);

	// Perform text animation
	useEffect(() => {
		if (openAiLlmResult) {
			chunkAndAnimateStr(openAiLlmResult, setOpenAiAnimated, 1.3);
		}
	}, [openAiLlmResult]);

	useEffect(() => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			const offset = suggestionsShown
				? RESULT_WITH_SUGGESTIONS_HEIGHT_OFFSET
				: RESULT_DEFAULT_HEIGHT_OFFSET;
			setResultHeight(window.innerHeight - offset);
		}, 300);
	}, [suggestionsShown, size.height, size.width]);

	const bothLoaded = !evolusionLoading && !openAiLoading;
	return (
		<div ref={containerRef} className={styles.container}>
			<div className={cn(styles.column, styles.leftColumn)}>
				<div className={styles.resultHeader}>
					<h5>Evo Chat</h5>
					{shareId && bothLoaded && (
						<Button
							emphasis="secondary"
							onClick={copyShareLink}
						>
							{shareBtnText}
						</Button>
					)}
				</div>
				<FormattedLLMOutput
					loading={evolusionLoading}
					content={evolusionAnimated}
					height={resultHeight}
				/>
			</div>
			<div className={cn(styles.column, styles.rightColumn)}>
				<div className={styles.resultHeader}>
					<h5>ChatGPT</h5>
				</div>
				<FormattedLLMOutput
					loading={openAiLoading}
					content={openAiAnimated}
					height={resultHeight}
				/>	
			</div>
		</div>
	)
}
