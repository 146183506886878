import { useRef, useState } from "react";
import { Paginator } from "../Paginator";
import styles from "./styles.module.scss";
import { VisualizerItem } from "./components/VisualizerItem";

const ENTRIES_PER_PAGE = 6;

export function ModelDataVisualizerPageLayout() {
	const [numPages, setNumPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [fileContent, setFileContent] = useState([]);
	const [fileParseErrorLines, setFileParseErrorLines] = useState([]);
	const containerRef = useRef(null);

	function safeParseJson(jsonObj, lineNumber) {
		if (jsonObj) {
			try {
				return JSON.parse(jsonObj);
			} catch (e) {
				setFileParseErrorLines((prev) => [...prev, lineNumber + 1]);
				return null;
			}
		}
		return null;
	}
	
	function processFile(fileData) {
		const fileContent = fileData.target.result
			.split(/[\r\n]+/g)
			.map(safeParseJson)
			.filter(Boolean);
		setNumPages(parseInt(fileContent.length / ENTRIES_PER_PAGE));
		setFileContent(fileContent);
	}
	
	function onFileUpload(e) {
		setFileParseErrorLines([]);
		const fileReader = new FileReader();
		fileReader.onloadend = processFile;
		fileReader.readAsText(e.target.files[0]);
	}

	function handlePageChange(page) {
		setCurrentPage(page);
		containerRef.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}

	const contentStartIdx = currentPage * ENTRIES_PER_PAGE;
	const contentEndIdx = contentStartIdx + ENTRIES_PER_PAGE;
	const fileItems = fileContent.slice(contentStartIdx, contentEndIdx);
	return (
		<div className={styles.container} ref={containerRef}>
			<div className={styles.fileInput}>
				<input
					type="file"
					onChange={onFileUpload}
					accept=".jsonl"
				/>
			</div>
			{fileParseErrorLines.length > 0 && (
				<span>
					Parsing errors on lines:
					{fileParseErrorLines.join(", ")}
				</span> 
			)}
			<div className={styles.visualizerItems}>
				{fileItems.map(({ ID, instruction, input, output, task_category: category }) => (
					<VisualizerItem
						key={ID}
						instruction={instruction}
						input={input}
						output={output}
						category={category}
					/>
				))}
			</div>
			<div className={styles.paginatorContainer}>
				<Paginator numPages={numPages} onPageChange={handlePageChange}/>
			</div>
		</div>
	);
}
