export function Logo() {
	return (
		<svg width="35" height="35" xmlns="http://www.w3.org/2000/svg">
			<g>
				<title>Layer 1</title>
				<g transform="rotate(-45 17.5 17.5)" id="svg_20">
				<rect rx="0.1" id="svg_17" height="21" width="21" y="7" x="7" strokeWidth="3" stroke="#000" fill="none"/>
				<line id="svg_18" y2="14" x2="28" y1="14" x1="14" strokeWidth="3" stroke="#000" fill="none"/>
				<line id="svg_19" y2="20.89655" x2="27.88877" y1="20.89655" x1="13.88877" strokeWidth="3" stroke="#000" fill="none"/>
				</g>
			</g>
		</svg>
	);
}