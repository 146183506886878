import { FormattedLLMOutput } from "../../../FormattedLLMOutput";
import styles from "./styles.module.scss";

export function VisualizerItem({
	instruction,
	input,
	output,
	category
}) {
	return (
		<div className={styles.container}>
			<div className={styles.detailLine}>
				<h5>Instruction</h5>
				<span>{instruction}</span>
			</div>
			<div className={styles.detailLine}>
				<h5>Input</h5>
				<span>{input}</span>
			</div>
			<div className={styles.detailLine}>
				<h5>Category</h5>
				<span>{category}</span>
			</div>
			<div className={styles.output}>
				<div className={styles.formattedOutput}>
					<FormattedLLMOutput
						loading={false}
						content={output}
						height={400}
					/>
				</div>
			</div>
		</div>
	);
}
