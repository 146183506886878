import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import styles from "./styles.module.scss";

export function CodeHighlighter({ node, inline, className, children, ...props }) {
	const match = /language-(\w+)/.exec(className || '');
	if (!inline && match) {
		const language = match[1];
		return (
			<>
				<div className={styles.languageHeader}>
					{language}
				</div>
				<SyntaxHighlighter
					{...props}
					style={atomDark}
					language={language}
					wrapLongLines={true}
					PreTag="div"
				>
					{String(children).replace(/\n$/, '')}
				</SyntaxHighlighter>
			</>
		);
	}
	if (!inline) {
		return (
			<div className={styles.codeBlock}>
				<code {...props} className={className}>
					{children}
				</code>
			</div>
		)
	}
	return (
		<code {...props} className={styles.inlineCode}>
			{children}
		</code>
	);
}
