import { Logo } from "../Logo";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

export function Header() {
	return (
		<div className={styles.container}>
			<Link to="/"><Logo /></Link>
			<h2>Evolusion AI</h2>
		</div>
	)
}
