import { useState } from "react";
import { Button } from "../Button";
import styles from "./styles.module.scss";

export function Paginator({ numPages, onPageChange }) {
	const [currentPage, setCurrentPage] = useState(0);

	function handlePageBtnClick(idx) {
		setCurrentPage(idx);
		onPageChange(idx);
	}

	return (
		<div className={styles.container}>
			{Array(numPages).fill().map((n, idx) => (
				<Button
					key={idx}
					onClick={() => handlePageBtnClick(idx)}
					emphasis={idx === currentPage ? "primary" : "secondary"}
				>
					{idx + 1}
				</Button>
			))}
		</div>
	)
}
