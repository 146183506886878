import ReactMarkdown from "react-markdown";
import { LoadingAnimation } from "../LoadingAnimation";
import remarkBreaks from "remark-breaks";
import { CodeHighlighter } from "../CodeHighLighter";
import styles from "./styles.module.scss";

export function FormattedLLMOutput({ loading, content, height }) {
	return (
		<div className={styles.container} style={{ height }}>
			{loading
				? <LoadingAnimation />
				: (
					<ReactMarkdown
						remarkPlugins={[remarkBreaks]}
						components={{ code: CodeHighlighter }}
					>
						{content}
					</ReactMarkdown>
				)
			}
		</div>
	)
}
