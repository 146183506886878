import cn from "classnames";
import styles from "./styles.module.scss";

export function Button({ className, emphasis = "primary", ...props }) {
	return (
		<button
			className={cn(
				styles.button,
				{
					[styles.primary]: emphasis === "primary",
					[styles.secondary]: emphasis === "secondary",
				},
				className
			)}
			{...props}
		/>
	);
}