import { useEffect, useState } from "react";

export function useResizeWatcher(elementRef) {
	const [size, setSize] = useState({ width: null, height: null });

	useEffect(() => {
		const resizeObserver = new ResizeObserver((entries) =>
			setSize({
				width: window.innerWidth,
				height: window.innerHeight,
			})
		);
		resizeObserver.observe(elementRef.current);

		return () => resizeObserver.disconnect();
	}, [elementRef?.current]);

	return size;
}
