import { forwardRef } from "react"

export function icon(ImportedSvg) {
	function renderIcon({ size, ...props }, ref) {
		return (
			<ImportedSvg
				ref={ref}
				height={size}
				width={size}
				{...props}
			/>
		)
	}

    const Icon = forwardRef(renderIcon);
    return Icon;
}
